import React, { useContext, useState, useMemo, useCallback } from 'react';
import { _CASH_SYMBOL } from 'utils/symbol';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { get, set } from 'lodash';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import ThanksDialog from 'features/thanksDialog/ThanksDialog';
import feathers from 'services/feathers';
import CommonContext from 'features/context/commonContext';
import Decimal from 'decimal.js';
import Loader from 'features/loader/Loader';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import InputAdornment from '@mui/material/InputAdornment';
import { useNavigate } from "react-router-dom";
import TurnoverInfo from 'features/withdrawal/TurnoverInfo';
import Alert from '@mui/material/Alert';

export default function Withdrawal() {
  const { t } = useTranslation();
  const [ status, setStatus ] = useState('idle');
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();
  const [ openThanksDialog, setOpenThanksDialog ] = useState(false);
  const { wallet, walletReady, companySetting } = useContext(CommonContext);
  const navigate = useNavigate();

  const noDecimalRule = useMemo(
    () => {
      const country = get(companySetting, 'country', '');

      return country === 'au';
    }, [companySetting]
  );

  const withdrawalLimit = useMemo(
    () => {
      const {
        minWithdrawal: {
          '$numberDecimal': minWithdrawal = 50
        } = {},
        maxWithdrawal: {
          '$numberDecimal': maxWithdrawal = 30000
        } = {}
      } = companySetting?.transaction || {};

      return {
        min: Number(minWithdrawal),
        max: Number(maxWithdrawal),
      }
    }, [companySetting]
  );

  const savedBank = useMemo(
    () => {
      return get(wallet, 'savedBank');
    }, [wallet]
  );

  const decCashBalance = useMemo(
    () => {
      if (!walletReady) new Decimal(0);
      const cashBalance = new Decimal(get(wallet, 'cashBalance.$numberDecimal', '0'));
      return cashBalance;
    }, [walletReady, wallet]
  );

  const decMinAmount = useMemo(
    () => {
      const min = new Decimal(withdrawalLimit?.min || '50');
      return decCashBalance.lt(min) ? decCashBalance : min;
    }, [decCashBalance, withdrawalLimit]
  );

  const decMaxAmount = useMemo(
    () => {
      const max = new Decimal(withdrawalLimit?.max || '30000');
      return decCashBalance.gt(max) ? max : decCashBalance;
    }, [decCashBalance, withdrawalLimit]
  );

  const defaultWithdrawalAmount = useMemo(
    () => {
      const zeroAmount = new Decimal(0);
      return noDecimalRule ? zeroAmount.toFixed(0) : zeroAmount.toFixed(2);
    }, [noDecimalRule]
  );

  const blockIfNonZeroCashBalance = useMemo(
    () => {
      return get(companySetting, 'depositRules.blockIfNonZeroCashBalance', false);
    }, [companySetting]
  );

  const withdrawalSchema = Yup.object().shape({
    amount: Yup.number().required(t('Required'))
      .min(decMinAmount.toNumber(), t('Min amount', { amount: decMinAmount.toNumber() }))
      .max(decMaxAmount.toNumber(), t('Max amount', { amount: decMaxAmount.toNumber() }))
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      isTurnoverAgreed: false,
      amount: defaultWithdrawalAmount,
    },
    validationSchema: withdrawalSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        await feathers.service('withdrawals').create({
          amount: values.amount,
        });
        setStatus('idle');
        setOpenThanksDialog(true);
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    if (status !== 'idle') return;
    if (!savedBank) {
      navigate('/wallet/withdrawal', {
        state: {
          lastBankReminderPrompt: new Date()
        }
      });
      return;
    }
    formik.submitForm();
  };

  const handleOnAmountChange = useCallback(
    (event) => {
      event.preventDefault();
      const value = get(event, 'target.value', '');
      const sanitizedValue = value.replace(/\D/g, '') || '0';
      const amountRaw = new Decimal(sanitizedValue);

      if (noDecimalRule) {
        const amountStr = amountRaw.gt(decMaxAmount) ? decMaxAmount.toFixed(0) : amountRaw.toFixed(0);
        formik.setFieldValue('amount', amountStr);
        return;
      }

      const amount = amountRaw.dividedBy(100);
      const amountStr = amount.gt(decMaxAmount) ? decMaxAmount.toFixed(2) : amount.toFixed(2);
      formik.setFieldValue('amount', amountStr);
    }, [formik, decMaxAmount, noDecimalRule]
  );

  const handleAutoAmount = useCallback(
    (mode) => (event) => {
      event?.preventDefault();
      let value = '0.00';

      if (noDecimalRule) {
        if (decMinAmount.equals(decMaxAmount)) value = decMinAmount.toFixed(0);
        else if (mode === 'min') value = decMinAmount.toFixed(0);
        else if (mode === 'max') value = decMaxAmount.toFixed(0);
        else if (mode === '1by2') value = decMaxAmount.times(0.5).round().toFixed(0);
        else if (mode === '3by4') value = decMaxAmount.times(0.75).round().toFixed(0);

        set(event, 'target.value', value);
        handleOnAmountChange(event);
        return;
      }

      if (decMinAmount.equals(decMaxAmount)) value = decMinAmount.toFixed(2);
      else if (mode === 'min') value = decMinAmount.toFixed(2);
      else if (mode === 'max') value = decMaxAmount.toFixed(2);
      else if (mode === '1by2') value = decMaxAmount.times(0.5).round().toFixed(2);
      else if (mode === '3by4') value = decMaxAmount.times(0.75).round().toFixed(2);

      set(event, 'target.value', value);
      handleOnAmountChange(event);
    }, [handleOnAmountChange, decMinAmount, decMaxAmount, noDecimalRule]
  );

  if (!walletReady) return (
    <Loader open={true} />
  );

  return (
    <Box sx={{ maxWidth: 'sm', mx: 'auto' }}>
      <ThanksDialog open={openThanksDialog} />
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Card elevation={3}>
            <CardHeader title={t('Withdrawal Header')} />
            <Divider />
            <CardContent>
              <Box>
                <Grid spacing={1} container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs={12}>
                    <Box>
                      <TextField
                        fullWidth
                        inputProps={{ inputMode: 'numeric' }}
                        inputMode='numeric'
                        id='amount'
                        name='amount'
                        label={t('Amount')}
                        value={formik.values.amount}
                        onBlur={formik.handleBlur}
                        onChange={handleOnAmountChange}
                        error={formik.touched.amount && Boolean(formik.errors.amount)}
                        helperText={formik.touched.amount && formik.errors.amount}
                        InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            {_CASH_SYMBOL}
                          </InputAdornment>
                        )}}
                      />
                    </Box>
                  </Grid>
                  {
                    ['min', '1by2', '3by4', 'max'].map(label => {
                      return (
                        <Grid item xs={3} key={label}>
                          <Button fullWidth onClick={handleAutoAmount(label)} color='info' variant='contained' size='small'>{t(label)}</Button>
                        </Grid>
                      )
                    })
                  }
                  {
                    blockIfNonZeroCashBalance && decCashBalance.gt(0) && (
                      <Grid item xs={12}>
                        <Alert severity='warning'>{t('warning.depositRules.blockIfNonZeroCashBalance')}</Alert>
                      </Grid>
                    )
                  }
                </Grid>
              </Box>
            </CardContent>
            <Divider />
            <CardActions sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Button size='large' variant='contained' onClick={handleSubmit}>
                {t('Submit Now')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <TurnoverInfo />
        </Grid>
      </Grid>
    </Box>
  );
}
