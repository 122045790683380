import ContentContext from './companySettingContext';
import feathers from 'services/feathers';
import React, { useState, useEffect, useMemo } from 'react';

function CompanySettingProvider({ children }) {
  const [ companyId, setCompanyId ] = useState('');
  const [ supportedLocales, setSupportedLocales ] = useState(['en']);
  const [ defaultLocale, setDefaultLocale ] = useState('en');
  const [ supportedCountryCodes, setSupportedCountryCodes ] = useState([]);
  const [ pageConfig, setPageConfig ] = useState({});
  const [ depositRules, setDepositRules ] = useState({});
  const [ withdrawalRules, setWithdrawalRules ] = useState({});
  const [ redemptionRules, setRedemptionRules ] = useState({});
  const [ ready, setReady ] = useState(false);

  useEffect(
    () => {
      const companyIdFromStorage = localStorage.getItem('org');
      if (!companyIdFromStorage) return;
      setCompanyId(companyIdFromStorage);
    }, []
  );

  const explicitCompanyId = useMemo(
    () => {
      const url = new URL(document.location.href);
      const companyIdFromQuery = url.searchParams.get('org');

      if (companyIdFromQuery) {
        return companyIdFromQuery;
      }

      const companyIdFromStorage = localStorage.getItem('org');
      if (companyIdFromStorage) {
        return companyIdFromStorage;
      }

      return '';
    }, []
  );

  useEffect(
    () => {
      async function getSupportedCountryCodes() {
        try {
          const { data } = await feathers.service('company-settings').find({
            ...(explicitCompanyId && {
              query: {
                companyId: explicitCompanyId
              }
            })
          });

          const companySetting = data?.[0] || {};

          const {
            companyId,
            supportedLocales = ['en'],
            defaultLocale = 'en',
            supportedCountryCodes = [],
            pageConfig = {},
            depositRules = {},
            withdrawalRules = {},
            redemptionRules = {},
          } = companySetting;

          const defaultLocaleIndex = supportedLocales.indexOf(defaultLocale);
          if (defaultLocaleIndex > 0) {
            supportedLocales.splice(defaultLocaleIndex, 1);
            supportedLocales.unshift(defaultLocale);
          }

          setCompanyId(companyId);
          setSupportedLocales(supportedLocales);
          setDefaultLocale(defaultLocale);
          setSupportedCountryCodes(supportedCountryCodes);
          setPageConfig(pageConfig);
          setDepositRules(depositRules);
          setWithdrawalRules(withdrawalRules);
          setRedemptionRules(redemptionRules);
        } catch (err) {
          console.error(err);
        } finally {
          setReady(true);
        }
      }

      getSupportedCountryCodes();
    }, [explicitCompanyId]
  );

  return (
    <ContentContext.Provider value={{
      companyId,
      supportedLocales,
      defaultLocale,
      supportedCountryCodes,
      pageConfig,
      depositRules,
      withdrawalRules,
      redemptionRules,
      ready,
    }}>
      {children}
    </ContentContext.Provider>
  );
}

export default CompanySettingProvider;
